<template>
	<div class="blog">

		<Head :title="Head.Title" :baliseTitle="Head.baliseTitle" :titleWeight="Head.TitleWeight"
			:subTitle="Head.SubTitle" :subTitle2="Head.SubTitle2" :bgUrl="Head.bgUrl" :bgUrlLazy="Head.bgUrlLazy"
			:offset="Head.offset" :height="Head.height" :overlayOpacity="Head.overlayOpacity"
			:overlayColor="Head.overlayColor" :imgBlur="Head.imgBlur" :transitionRotate="Head.transitionRotate"
			:titleColor="Head.TitleColor" />

		<v-container>
			<v-card class="glassmorphism pa-4 pt-0 mx-auto" max-width="1280" color="transparent">
				<ButtonTrigger @click.native="dialog.add = !dialog.add" title="Ajouter un Article" :noButton="false" />
				<v-col cols="12">
					<v-card>
						<v-card-title>
							<h2 class="font-weight-regular">En-tête</h2>
						</v-card-title>
						<v-card-text>
							<v-row>
								<v-col cols="12">
									<v-text-field height="2em" style="font-size: 2em;" v-model="article.title"
										:rules="$fieldsRules.required('nom')" label="Titre de l'article"></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field height="2em" style="font-size: 1.8em;" v-model="article.subTitle"
										label="Sous-titre"></v-text-field>
								</v-col>
								<v-col cols="12">
									<h1 class="headline mb-2">Tags</h1>
									<v-row>
										<v-col>
											<v-autocomplete :loading="AIGeneration.tags" chips clearable deletable-chips multiple rounded :items=" tags" v-model="article.tags"></v-autocomplete>
										</v-col>
										<v-col cols="auto">
											<v-btn :block="$vuetify.breakpoint.xsOnly" :loading="AIGeneration.tags" @click="writeMeAThing(article.title, 'tags')" outlined :color="$vuetify.theme.dark ? 'white' : 'primary'" class="neon evidence float-right">
												<v-icon left>mdi-cloud-question</v-icon><span>Générer les tags par l'IA</span>
											</v-btn>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12">
					<v-card>
						<v-card-title>
							<v-row>
								<v-col>
									<h2 class="font-weight-regular">Contenu</h2>
								</v-col>
								<v-col cols="auto">
									<v-btn :block="$vuetify.breakpoint.xsOnly" :loading="AIGeneration.introduction || AIGeneration.body || AIGeneration.tags" @click="writeMeAThing(article.title)" outlined :color="$vuetify.theme.dark ? 'white' : 'primary'" class="neon float-right">
										<v-icon left>mdi-cloud-question</v-icon><span>Générer l'article par l'IA</span>
									</v-btn>
								</v-col>
							</v-row>
						</v-card-title>
						<v-card-text>
							<v-row>
								<v-col cols="12" class="pt-8">
									<h2 class="font-weight-light float-left">Introduction</h2>
									<Tooltip small right
										content="Insérez l'introduction de votre Article <br> Où générez-le par le module d'intelligence artificielle" />
								</v-col>
								<v-row class="px-3">
									<v-col cols="12" sm="6">
										<p class="font-weight-light">Taille de l'introduction
											({{ IAOptions.shortDesc }})
										</p>
										<v-slider :color="$vuetify.theme.dark ? 'white' : 'primary'"
											v-model="IAOptions.shortDesc" :step="Math.round(maxShortDesc / 8)"
											:max="maxShortDesc" min="150">
										</v-slider>
									</v-col>
									<v-col cols="12" sm="6">
										<v-btn :block="$vuetify.breakpoint.xsOnly" :loading="AIGeneration.introduction"
											@click="writeMeAThing(article.title, 'introduction')" outlined
											:color="$vuetify.theme.dark ? 'white' : 'primary'"
											class="neon evidence float-right">
											<v-icon left>mdi-cloud-question</v-icon><span>Générer l'introduction par l'IA</span>
										</v-btn>
									</v-col>
								</v-row>
								<v-col cols="12">
									<vue-editor v-model="article.shortDesc" />
								</v-col>
								<v-col cols="12" class="pt-8">
									<h2 class="font-weight-light float-left">Corps</h2>
									<Tooltip small right
										content="Insérez le texte princpal de votre Article <br> Où générez-le par le module d'intelligence artificielle" />
								</v-col>
								<v-row class="px-3">
									<v-col cols="12" sm="6">
										<p class="font-weight-light">Taille de l'article ({{ IAOptions.longDesc }})
										</p>
										<v-slider :color="$vuetify.theme.dark ? 'white' : 'primary'"
											v-model="IAOptions.longDesc" :step="Math.round(maxLongDesc / 8)"
											:max="maxLongDesc" min="150">
										</v-slider>
									</v-col>
									<v-col cols="12" sm="6">
										<v-btn :block="$vuetify.breakpoint.xsOnly" :loading="AIGeneration.body"
											@click="writeMeAThing(article.title, 'body')" outlined
											:color="$vuetify.theme.dark ? 'white' : 'primary'"
											class="neon evidence float-right">
											<v-icon left>mdi-cloud-question</v-icon><span>Générer le corps par l'IA</span>
										</v-btn>
									</v-col>
								</v-row>
								<v-col cols="12">
									<vue-editor v-model="article.longDesc" />
								</v-col>
								<v-col cols="12" class="pt-8">
									<h2 class="font-weight-light float-left">Mentions Complémentaire</h2>
									<Tooltip small right
										content="Les mentions complémentaires sont ajoutée après l'article <br> Il contient bien souvent les NDLR, mentions légales, précisions diverses" />
									<vue-editor class="pt-8" v-model="article.disclaimer" />
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12">
					<v-card>
						<v-card-title>
							<h2 class="font-weight-regular">Images</h2>
						</v-card-title>
						<v-card-text>
							<v-row>
								<v-col cols="12">
									<v-card-text>
										<v-row>
											<v-col cols="12" sm="6">
												<h3 class="font-weight-light">Pour le bandeau de l'article :</h3>
												<ImageUpload v-if="userInfo.company" :companyID="userInfo.company" type="blog"
													@newdata="article.img.secondary=$event" />
											</v-col>
											<v-col cols="12" sm="6">
												<v-img v-if="article.img.secondary" max-height="250" contain
													:src="$functions.getImgLink(article.img.secondary)"></v-img>
											</v-col>
										</v-row>
										<v-row>
											<v-col cols="12" sm="6">
												<h3 class="font-weight-light">Pour le slider :</h3>
												<ImageUpload v-if="userInfo.company" :companyID="userInfo.company" type="blog"
													@newdata="article.img.main=$event" />
											</v-col>
											<v-col cols="12" sm="6">
												<v-img v-if="article.img.main" max-height="250" contain
													:src="$functions.getImgLink(article.img.main)">
												</v-img>
											</v-col>
										</v-row>
									</v-card-text>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>

				<v-col cols="12">
					<v-card>
						<v-card-title>
							<h2 class="font-weight-regular">Paramètres</h2>
						</v-card-title>
						<v-card-text>
							<v-row>
								<v-col cols="12">
									<v-card-text>
										<v-switch v-model="article.contact" label="Formulaire de contact"></v-switch>
										<v-switch v-model="options.callToAction" label="Bouton CallToAction"></v-switch>
									</v-card-text>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>

				<v-expand-transition>
					<v-col cols="12" v-if="options.callToAction">
						<v-card>
							<v-card-title>
								<h2 class="font-weight-regular">CallToAction</h2>
							</v-card-title>
							<v-card-text>
								<v-row>
									<v-col cols="12">
										<v-card-text>
											<v-text-field label="Call To Action" v-model="article.callToAction">
											</v-text-field>
											<h3 class="font-weight-light">Previsualisation:</h3>
											<v-row style="max-width: 500px;" class="pt-4 mx-auto">
												<v-btn v-if="article.callToAction" min-width="200" class="mx-auto my-2">
													{{ article.callToAction }}</v-btn>
												<v-btn v-if="article.callToAction" outlined min-width="200"
													class="mx-auto my-2">
													{{ article.callToAction }}</v-btn>
											</v-row>
										</v-card-text>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-col>
				</v-expand-transition>

				<v-col cols="12" align="center" justify="center">
					<v-btn
						:disabled="!article.title && !article.subTitle && !article.shortDesc && !article.longDesc && !article.disclaimer"
						@click="preview = !preview" :block="$vuetify.breakpoint.xsOnly">
						Prévisualisation de l'article
					</v-btn>
				</v-col>

				<v-expand-transition>
					<v-col cols="12" v-if="preview">
						<v-card class="preview">
							<v-card-title>
								<h2 class="font-weight-light float-left">Prévisualisation</h2>
								<Tooltip small right content="Le prévisualisation est une indication de la hiérarchie de l'article <br>
									Il est possible que le resultat final diffère une fois publié sur le site" />
							</v-card-title>

							<v-img v-if="article.img.secondary" width="100%" contain :src="article.img.secondary">
							</v-img>

							<v-card-text>
								<h1>{{article.title}}</h1>
								<h2 class="mt-2">{{article.subTitle}}</h2>
							</v-card-text>
							<v-card-text>
								<p v-html="article.shortDesc"></p>
								<p v-html="article.longDesc"></p>
								<p v-html="article.disclaimer"></p>
							</v-card-text>
							<v-card-text align="center">
								<v-btn v-if="options.callToAction" min-width="200" class="my-2">
									{{ article.callToAction }}</v-btn>
							</v-card-text>
						</v-card>
					</v-col>
				</v-expand-transition>



				<v-col cols="12">
					<v-card>
						<v-card-title>
							<h2 class="font-weight-regular">Publication</h2>
						</v-card-title>
						<v-card-text>
							<v-row>
								<v-col cols="12" align="center" justify="center">
									<v-card-text>
										<v-btn :disabled="AIGeneration.introduction || AIGeneration.body || AIGeneration.tags" :block="$vuetify.breakpoint.xsOnly"
											class="neon evidence mb-4" width="200" outlined
											:color="$vuetify.theme.dark ? 'white' : 'success'" @click="addArticle()"
											v-if="!modArticle">
											<span v-if="!AIGeneration.introduction && !AIGeneration.body && !AIGeneration.tags">Publier <v-icon right>mdi-send</v-icon></span>
											<span v-else>En attente de l'IA</span>
										</v-btn>
										<v-btn class="neon evidence mb-4" width="200" outlined
											:color="$vuetify.theme.dark ? 'white' : 'success'" @click="modifyArticle()"
											v-else>
											Modifier <v-icon small right>mdi-edit</v-icon>
										</v-btn>
									</v-card-text>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>


			</v-card>
		</v-container>
	</div>
</template>

<style>
	.preview img {
		width: 150px !important;
		margin: 0 auto;
	}

</style>

<script>
	import {
		VueEditor
	} from "vue2-editor";
	import ImageUpload from '@/components/graphics/ImageUpload';
	import Head from '@/components/graphics/head'
	import ButtonTrigger from '@/components/graphics/buttonTrigger'
	import Tooltip from '@/components/graphics/tooltipIcon'

	export default {
		head: {
			title: {
				inner: "Ajouter un article - Blog"
			}
		},
		name: 'AddBlog',
		props: {
			'id': {
				type: String
			},
			'modArticle': {
				type: String
			}
		},
		components: {
			Head,
			Tooltip,
			VueEditor,
			ButtonTrigger,
			ImageUpload
		},
		data() {
			return {

				Head: {

					Title: 'Article',
					baliseTitle: 'h2', // h1, h2, h3, h4, h5, h6
					TitleWeight: 'thin', // thin / light / regular / medium / bold / black
					TitleColor: 'white', // white / black / primary

					SubTitle: '',
					SubTitle2: "",

					bgUrl: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg.jpg",
					bgUrlLazy: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg-lazy.jpg",

					offset: "15vh",
					height: "65vh",

					overlayOpacity: "0.3",
					overlayColor: "black",
					imgBlur: "0px",

					transitionRotate: "180"

				},

				preview: false,

				userInfo: this.$models.user,

				blogInfo: this.$models.blog,

				article: {
					...this.$models.blogArticle,
					tags: []
				},

				AIGeneration: {
					introduction: false,
					body: false,
					tags: false
				},

				options: {
					callToAction: false
				},

				IAOptions: {
					shortDesc: 256,
					longDesc: 1024,
				},

				tags: []
			};
		},
		computed: {
			maxShortDesc: function () {
				return Math.abs(Math.round(this.article.title.length - 400));
			},
			maxLongDesc: function () {
				return Math.abs(Math.round(2048 - Math.abs(this.article.title.length - this.IAOptions.shortDesc)));
			}
		},
		methods: {
			async writeMeAThing(prompt, type=true) {
				if(type === "introduction" || type === true) {
					this.AIGeneration.introduction = true;

					await this.$ai.writeMeAThing({
						company: this.userInfo.company,
						id: this.userInfo.id
					},
					`Écrit moi une introduction d'article sur ${prompt}`,
					this.IAOptions.shortDesc).then((value) => {
						if (!value) return this.$store.dispatch('setSnackbar', {
							text: "Votre quota d'utilisation de l'IA est épuisé.",
							color: "error"
						});
						if(value.data.choices[0].finish_reason === "length") this.$store.dispatch('setSnackbar', {
							text: "L'IA n'a pas terminé sa phrase car la taille de l'introduction est trop restreinte.",
							color: "warning"
						});
						this.article.shortDesc = String(value.data.choices[0].text).trim();

						this.AIGeneration.introduction = false;
					});
				}

				if(type === "body" || type === true) {
					this.AIGeneration.body = true;

					await this.$ai.writeMeAThing({
						company: this.userInfo.company,
						id: this.userInfo.id
					},
					`Écrit moi un long article sur le sujet de ${this.article.title} avec ce texte : ${this.article.shortDesc} ${this.article.longDesc}`,
					this.IAOptions.longDesc).then(async (value) => {
						if (!value) return this.$store.dispatch('setSnackbar', {
							text: "Votre quota d'utilisation de l'IA est épuisé.",
							color: "error"
						});
						if(value.data.choices[0].finish_reason === "length") this.$store.dispatch('setSnackbar', {
							text: "L'IA n'a pas terminé sa phrase car la taille de l'article est trop restreinte.",
							color: "warning"
						});
						this.article.longDesc = String(value.data.choices[0].text).trim();
					});

					this.AIGeneration.body = false;
				}

				if(type === "tags" || type === true) {
					this.AIGeneration.tags = true;

					await this.$ai.generateKeywords({
						company: this.userInfo.company,
						id: this.userInfo.id
					},
					`${this.article.shortDesc} ${this.article.longDesc}`).then(async (value) => {
						if (!value) return this.$store.dispatch('setSnackbar', {
							text: "Votre quota d'utilisation de l'IA est épuisé.",
							color: "error"
						});
						this.article.tags = this.tags = value;
					});

					this.AIGeneration.tags = false;
				}

			},
			addArticle() {
				if (!this.article.title || (!this.article.shortDesc && !this.article.longDesc)) return;

				this.$db.collection("companies").doc(this.userInfo.company).collection("blogs").doc(this.id).collection(
					"articles").add({
					...this.article,
					creationDate: new Date(),
					author: this.userInfo.id
				}).then(() => {
					this.article = {
						title: String(),
						subTitle: String(),

						shortDesc: String(),
						longDesc: String(),

						img: {
							main: String(),
							secondary: String()
						},

						callToAction: "En savoir plus",

						contact: false
					};

					this.$store.dispatch('setSnackbar', {
						text: "L'article a bien été publié.",
						color: "success"
					});
				});
			},
			modifyArticle() {
				if (!this.article.title || (!this.article.shortDesc && !this.article.longDesc)) return;

				this.$db.collection("companies").doc(this.userInfo.company).collection("blogs").doc(this.id).collection(
					"articles").doc(this.modArticle).set({
					...this.article,
					modificationDate: new Date(),
					modificationAuthor: this.userInfo.id
				}, {
					merge: true
				}).then(() => {
					this.$store.dispatch('setSnackbar', {
						text: "L'article a bien été modifié.",
						color: "success"
					});
				});
			}
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = {
					...doc.data(),
					id: doc.id
				};

				this.$db.collection("companies").doc(this.userInfo.company).collection("blogs").doc(this.id)
					.onSnapshot(doc => {
						this.blogInfo = {
							...doc.data(),
							id: doc.id
						}
					});

				if (this.modArticle) {
					this.$db.collection("companies").doc(this.userInfo.company).collection("blogs").doc(this.id)
						.collection("articles").doc(this.modArticle).onSnapshot(doc => {
							if (!doc.data()) {
								this.$store.dispatch('setSnackbar', {
									text: "Article introuvable.",
									color: "error"
								});

								return this.$router.push({
									path: '/blog/' + this.id
								});
							}

							this.article = doc.data()
						});
				}
			});
		}
	}

</script>
