<template>
	<v-menu>
		<template v-slot:activator="{ on: menu, attrs }">
			<v-tooltip bottom>
				<template v-slot:activator="{ on: tooltip }">
					<v-icon :right="right" :left="left" :small="small" v-bind="attrs" v-on="{ ...tooltip, ...menu }">{{icon}}
					</v-icon>
				</template>
				<span v-html="content"></span>
			</v-tooltip>
		</template>
	</v-menu>
</template>

<script>
	export default {
		props: {
			content: {
				type: String,
				default: 'Default title'
			},
            icon: {
				type: String,
				default: 'mdi-help-circle-outline'
			},
            right: {
                type: Boolean,
                default: false,
            },
            left: {
                type: Boolean,
                default: false,
            },
            small: {
                type: Boolean,
                default: false,
            },
		},
		data() {
			return {
			};
		},
	}

</script>
